import React from 'react';

const Check = () => {
	return (
		<div style={{ width: 24, height: 24, flexShrink: 0 }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				className="bg-primary rounded-circle"
				style={{ padding: '2px' }}
			>
				<path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
			</svg>
		</div>
	);
};

export default Check;
