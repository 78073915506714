import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

export const createContact = ({ email, city, name, phone }) => {
	const domain = '/.netlify/functions/create-contact';

	const query = queryString.stringify({
		name,
		email,
		city,
		phone,
	});

	return axios.get(`${domain}?${query}`);
};

export const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const HUNGARIAN_DAYS = {
	Sunday: 'Vasárnap',
	Monday: 'Hétfő',
	Tuesday: 'Kedd',
	Wednesday: 'Szerda',
	Thursday: 'Csütörtök',
	Friday: 'Péntek',
	Saturday: 'Szombat',
};

export const hungarianDayFormat = (englishDay) => HUNGARIAN_DAYS[englishDay];

export const formatDateToTimeSlot = (eta, deliveryType) => {
	const intervalLookup = JSON.parse(
		process.env.GATSBY_PARTNER_DROP_OFF_INTERVAL
	);

	return `${moment(eta)
		.subtract(intervalLookup[deliveryType], 'm')
		.format('HH:mm')} - ${moment(eta)
		.add(intervalLookup[deliveryType], 'm')
		.format('HH:mm')}`;
};

export const nextFiveWeekdaysArray = [1, 2, 3, 4, 5, 6, 7].reduce(
	(weekdays, day) => {
		const next = moment().add(day, 'd').startOf('day');

		// not weekend
		if (!(next.day() % 6 === 0)) {
			weekdays.push(next._d);
		}

		return weekdays;
	},
	[]
);

export const formatDatesForSelect = (dates) =>
	dates.map((date) => {
		const mDate = moment(date);
		return {
			value: date - 0,
			text: `${mDate.format('YYYY.MM.DD')} ${hungarianDayFormat(
				mDate.format('dddd')
			)}`,
		};
	});
