import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import '../styles/courier.css';
import Check from '../icons/Check';

import { CourierForm } from '../components/shared/CourierForm';
import CourierIllustration from '../components/common/CourierIllustration';

const CourierPage = ({ data }) => (
	<Layout footerImg={data.logo_footer} footerEitImg={data.logo_eit_footer}>
		<section id="courier-form" className="pb-0 bg-white">
			<div className="container">
				<div className="row pt-sm-5">
					<div className="col-12 col-lg-6">
						<h3 className="text-center text-sm-left my-4 mt-sm-0 ml-md-0">
							Jelentkezz ViddL futárnak
						</h3>
						<p className="pb-4">
							Mi a ViddL-nél hiszünk abban, hogy a futároknak is lehet
							családbarát munkahelye. Éppen ezért, nálunk csak hétköznap
							történik munkavégzés, normál munkaidőben, amit a saját életedhez
							igazíthatsz.
						</p>
						<p className="subtitle-1 pb-4">
							Küldd el jelentkezésed és kollégáink felveszik veled a
							kapcsolatot!
						</p>
						<CourierForm />
					</div>
					<div className="col-12 col-lg-6 d-none d-sm-block pt-5 px-5">
						<div
							className="border rounded"
							style={{ borderColor: '#D3CFFC', padding: '32px 48px' }}
						>
							<CourierIllustration />
							<p className="subtitle-1 pt-4 pb-2">
								A ViddL Flotta és Munkavállalói Jóléti Programjainknak
								köszönhetően olyan extra dolgokban részesülhetsz:
							</p>
							<div className="d-flex align-items-start pt-3 pb-2">
								<Check />
								<span className="ml-2">
									Fix csomagszám, tervezhető munkaidővel
								</span>
							</div>
							<div className="d-flex align-items-start pb-2">
								<Check />
								<span className="ml-2">
									Minden hónapban a fizetéseden felül akár 50 000 Ft Flotta fizu
								</span>
							</div>
							<div className="d-flex align-items-start pb-2">
								<Check />
								<span className="ml-2">
									Felszerelt, korszerű konyha és pihenőszoba, céges fogászati
									támogatás
								</span>
							</div>
							<div className="d-flex align-items-start pb-2">
								<Check />
								<span className="ml-2">Kiemelten magas címsűrűség</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		logo_eit_footer: file(relativePath: { eq: "eit.png" }) {
			childImageSharp {
				fixed(width: 200, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default CourierPage;
