import React from 'react';
import { Formik, Form, Field } from 'formik';
import { navigate } from 'gatsby';
import { wrapComponent, SnackbarProvider } from 'react-snackbar-alert';

import { createContact, emailIsValid } from '../../utils';
import { TextField } from '../form/TextField';
import { SelectField } from '../form/SelectField';
import { CheckboxField } from '../form/CheckboxField';

const cityOptions = JSON.parse(process.env.GATSBY_CITY_OPTIONS || '[]');
const initialValues = {
	name: '',
	email: '',
	city: cityOptions[0] ? cityOptions[0].value : '',
	phone: '',
	accepttos: false,
};

const validate = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = 'Kötelező mező';
	} else if (!values.email) {
		errors.email = 'Kötelező mező';
	} else if (!values.city) {
		errors.city = 'Kötelező mező';
	} else if (!values.phone) {
		errors.phone = 'Kötelező mező';
	} else if (!emailIsValid(values.email)) {
		errors.email = 'Helytelen e-mail formátum';
	} else if (!values.accepttos) {
		errors.accepttos = 'Kötelező mező';
	}
	return errors;
};

const Container = wrapComponent(({ createSnackbar }) => {
	const onSubmit = async (values, { setSubmitting }) => {
		try {
			const { data } = await createContact(values);

			setSubmitting(false);

			if (data === 'CONTACT_EXISTS') {
				navigate('/error', { state: { type: 'already-applied' } });
				return;
			}

			navigate('/success');
		} catch (err) {
			createSnackbar({
				message:
					err.response.data || 'Hiba történt, kérlek lépj kapcsolatba velünk.',
				theme: 'error',
			});
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<Field name="name" label="Teljes neved" component={TextField} />
					<Field
						type="email"
						name="email"
						label="E-mail címed"
						component={TextField}
					/>
					<Field
						name="city"
						label="Városod"
						component={SelectField}
						options={cityOptions}
					/>
					<Field name="phone" label="Telefonszám" component={TextField} />
					<Field
						name="accepttos"
						label="Elfogadom az Adatvédelmi nyilatkozatot"
						component={CheckboxField}
					/>
					<button
						type="submit"
						className="btn btn-light my-3"
						disabled={isSubmitting}
					>
						Leadom a jelentkezésemet
					</button>
				</Form>
			)}
		</Formik>
	);
});

export const CourierForm = function CourierForm() {
	return (
		<SnackbarProvider position="bottom">
			<Container />
		</SnackbarProvider>
	);
};
